<template>
  <base-missing-data class="employees-missing-data" :icon="$options.imageDataMissing" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataEmployees from '@/components/MissingDataIcons/MissingDataEmployees.vue';

export default {
  name: 'EmployeesMissingData',

  components: { BaseMissingData },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  computed: {
    message() {
      return this.isArchive ? this.$t('employees.archived_missing_message') : this.$t('employees.missing_message');
    },
  },

  IconMissingDataEmployees,
};
</script>
