<template>
  <div class="employees" :class="{ 'employees--archive': isArchive }">
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('employees.back_to_employees') }}</v-btn>
      <v-row v-else no-gutters>
        <v-col class="pr-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectAllInPage"
            >{{ $t('button.on_page') }} ({{ pageEmployeesCount }})</v-btn
          >
        </v-col>

        <v-col class="pl-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectGlobal"
            >{{ $t('button.all') }} ({{ employeesCount }})</v-btn
          >
        </v-col>
      </v-row>
    </info-modal>

    <entity-status-list :statuses="entityEmployeesStatuses">
      <template v-if="canDisplayActions">
        <template v-if="!isArchive && !media.isMobile">
          <v-btn large class="mr-3" elevation="0" color="primary" :to="employeeRoute">
            {{ $t('button.add_employees') }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </template>
        <employees-menu
          :status="status"
          :page="pageModel"
          @archive-employees="archiveEmployees"
          @unarchive-employees="unarchiveEmployees"
        />
      </template>
    </entity-status-list>

    <employees-missing-data v-if="isDataMissing" class="employees__missing-data" :is-archive="isArchive" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <mobile-sort
          class="mt-2"
          :reverse="canUpdateEmployees"
          :sort-list="employeesHeaders"
          :sort-by="activeHeader"
          :options.sync="options"
        >
          <employees-menu
            v-if="canUpdateEmployees"
            :page="pageModel"
            :status="status"
            @archive-employees="archiveEmployees"
            @select-employees="selectAll"
            @unarchive-employees="unarchiveEmployees"
          />
        </mobile-sort>

        <mobile-search-menu
          :key="$route.name"
          v-model="queryModel"
          :placeholder="$t('label.employees_search')"
          single-line
          full-width
          hide-details
        />
      </div>

      <div class="employees__list">
        <list-loading v-if="isLoading" />
        <template v-else>
          <search-bar
            v-if="!media.isMobile"
            :key="$route.name"
            v-model="queryModel"
            class="mb-6"
            :placeholder="$t('label.employees_search')"
            single-line
            full-width
            hide-details
          />
          <employees-mobile-list
            v-if="media.isMobile"
            v-model="selected"
            :items="employees"
            :can-update-employees="canUpdateEmployees"
            @open="openDetailed"
          />

          <base-table
            v-else
            v-model="selected"
            hide-default-footer
            show-select
            checkbox-color="primary"
            :items="employees"
            :headers="employeesHeaders"
            :loading="tableLoading"
            :options.sync="options"
            :server-items-length="employeesLimit"
            @click:row="openDetailed"
          >
            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-menu
                v-if="!media.isMobile"
                v-model="dialogIsOpen"
                :close-on-content-click="false"
                offset-y
                :value="true"
              >
                <template #activator="{ on: modalActivator }">
                  <v-simple-checkbox
                    v-bind="props"
                    :ripple="false"
                    @click="selectAll(props, modalActivator)"
                    v-on="on"
                  />
                </template>
                <v-card max-width="352">
                  <v-card-text class="text-subtitle-2">
                    {{ $t('employees.select_info') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
                      >{{ $t('button.all_in_page') }} ({{ pageEmployeesCount }})</v-btn
                    >
                    <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
                      >{{ $t('button.all') }} ({{ employeesCount }})</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:item.credentials="{ item }">
              {{ joinWithLimitTranslate(item.credentials, 2) }}
            </template>
            <template v-slot:item.roles="{ item }">
              {{ joinWithLimitTranslate(item.roles, 2) }}
            </template>
            <template v-slot:item.projectsName="{ item }">
              {{ joinWithLimit(item.projectsName, 2) }}
            </template>
          </base-table>
        </template>
      </div>

      <base-pagination v-if="pageCount && !isLoading" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// Http
import client from '@/http/client';

// Constants
import { EMPLOYEES_DETAILED, EMPLOYEES, EMPLOYEES_ARCHIVE, ROLES, EMPLOYEES_CREATE } from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';
import { EMPLOYEES_LIST } from '@/constants/analyticsActions';
import { EMPLOYEES as EMPLOYEES_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Utils
import { throttle } from '@/utils/delay';
import { camelToSnake } from '@/utils/formatters';
import { flushPromises } from '@/utils/scheduler';
import joinWithLimit from '@/utils/joinWithLimit';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Components
import MobileSort from '@/components/MobileSort/index.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import ListLoading from '@/components/ListLoading.vue';
import EmployeesMobileList from '@/components/Employees/MobileList.vue';
import EmployeesMissingData from '@/components/Employees/MissingData.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import SearchBar from '@/components/SearchBar.vue';
import EmployeesMenu from '@/components/Employees/Menu.vue';
import InfoModal from '@/components/InfoModal.vue';
import EntityStatusList from '@/components/EntityStatusList.vue';

export default {
  name: 'Employees',

  components: {
    MobileSort,
    MobileSearchMenu,
    ListLoading,
    EmployeesMobileList,
    EmployeesMissingData,
    BasePagination,
    BaseTable,
    SearchBar,
    EmployeesMenu,
    InfoModal,
    EntityStatusList,
  },

  inject: ['media'],

  props: {
    status: { type: String, required: true },
  },

  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      employeesLimit: 10,
      employeesCount: 0,
      employees: [],
      isLoading: false,
      tableLoading: false,
      isDataMissing: false,
      selected: [],
      globalSelect: false,
      dialogIsOpen: false,
      modal: {
        show: false,
        message: '',
        info: false,
      },
      options: { sortBy: '', sortDesc: null },
    };
  },

  computed: {
    canDisplayActions() {
      return (!this.media.isMobile && this.canUpdateEmployees) || this.isDataMissing;
    },

    canUpdateEmployees() {
      return this.$can(UPDATE, EMPLOYEES_SUBJECT);
    },

    pageEmployeesCount() {
      return this.employees.length;
    },

    entityEmployeesStatuses() {
      return [
        { id: 0, text: this.$t('employees.employees'), name: EMPLOYEES },
        { id: 1, text: this.$t('employees.archive'), name: EMPLOYEES_ARCHIVE },
        { id: 1, text: this.$t('employees.roles'), name: ROLES },
      ];
    },

    employeeRoute() {
      return { name: EMPLOYEES_CREATE };
    },

    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.employeesHeaders.find(employeeHeader => employeeHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },

    employeesHeaders() {
      return [
        {
          sortText: this.$t('sort_by.employee'),
          text: this.$t('employees.fullName'),
          value: 'fullName',
          sortable: true,
        },
        {
          sortText: this.$t('sort_by.email'),
          text: this.$t('employees.email'),
          sortable: true,
          value: 'email',
        },
        { text: this.$t('employees.phone'), value: 'phone', sortable: true },
        { text: this.$t('employees.credentials'), value: 'credentials', sortable: false },
        { text: this.$t('employees.roles'), value: 'roles', sortable: false },
        { text: this.$t('employees.projectsName'), value: 'projectsName', sortable: false },
      ];
    },

    activeHeader() {
      return this.employeesHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },

    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },

    offset() {
      return (this.page - 1) * this.employeesLimit;
    },

    isArchive() {
      return this.status === entityStatuses.ARCHIVED;
    },

    isShowRoles() {
      return this.status === entityStatuses.SHOW_ROLES;
    },
  },

  watch: {
    status() {
      this.query = '';
      this.selected = [];
      this.globalSelect = false;
    },

    selected(newValue) {
      if (newValue.length < this.pageClientsCount) {
        this.globalSelect = false;
      }
    },

    page() {
      this.selected = [];
      this.globalSelect = false;
      this.getEmployees();
    },

    orderBy() {
      this.getEmployees();
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
      }
    },

    // eslint-disable-next-line
    '$route.name': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.pageModel !== 1) {
        this.pageModel = 1;
        return;
      }
      if (this.isDataMissing) {
        this.isLoading = true;
        this.isDataMissing = false;
      }
      this.getEmployees().then(() => {
        if (this.employees.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      });
    },

    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$options.throttledSearch();
    },
  },

  mounted() {
    analyticsService.track(EMPLOYEES_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });

    this.getEmployees()
      .then(() => {
        if (this.employees.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getEmployees();
    }, 500);
  },

  methods: {
    joinWithLimit,
    joinWithLimitTranslate(array) {
      return this.joinWithLimit(array?.map(item => this.$t(item)));
    },
    selectAllInPage() {
      this.globalSelect = false;
      this.closeSelectDialog();
    },

    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },

    closeSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = false;
      } else {
        this.closeModal();
      }
    },

    openSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = true;
      } else {
        this.modal.message = this.$t('employees.select_info');
        this.modal.show = true;
      }
    },

    selectGlobal() {
      this.globalSelect = true;
      this.closeSelectDialog();
    },

    selectAll(props) {
      if (this.media.isMobile) {
        this.selected = this.employees;

        if (this.pageCount > 1) {
          this.openSelectDialog();
        }

        return;
      }

      if (!props?.value && this.pageCount > 1) {
        this.openSelectDialog();
      }
    },

    archiveEmployees() {
      const options = { isArchived: true, employees: this.selected.map(usage => usage.id) };

      const archivePromise = this.globalSelect
        ? usersService.archiveAllEmployees(options)
        : usersService.archiveEmployees(options);

      archivePromise
        .then(() => {
          this.getEmployees();
          this.showModal(this.$t('employ.archive_success'));
        })
        .catch(() => {
          this.showModal(this.$t('employ.archive_fail'));
        });
    },

    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },

    closeDialog() {
      this.dialogIsOpen = false;
    },

    unarchiveEmployees() {
      const options = { isArchived: false, employees: this.selected.map(usage => usage.id) };

      const archivePromise = this.globalSelect
        ? usersService.archiveAllEmployees(options)
        : usersService.archiveEmployees(options);

      archivePromise
        .then(() => {
          this.getEmployees();
          this.showModal(this.$t('employ.unarchive_success'));
        })
        .catch(() => {
          this.showModal(this.$t('employ.unarchive_fail'));
        });
    },

    async getEmployees() {
      if (this.$options.cancelRequestNewEmployees) {
        this.$options.cancelRequestNewEmployees();
        await flushPromises();
      }

      this.selected = [];
      this.tableLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestNewEmployees = cancelSource.cancel;

        const data = await usersService.getEmployees(
          {
            limit: this.employeesLimit,
            offset: this.offset,
            search: this.query,
            orderBy: this.orderBy,
            isArchived: this.status === entityStatuses.ARCHIVED,
          },
          {
            cancelToken: cancelSource.token,
          }
        );
        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.employeesLimit);

        this.employeesCount = count;

        this.employees = results.map(employee => this.normalizeData(employee));
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
        this.$options.cancelRequestNewEmployees = null;
      }
    },

    openDetailed(item) {
      this.$router.push({ name: EMPLOYEES_DETAILED, params: { id: item.id } });
    },

    normalizeData(employee) {
      return {
        id: employee.id,
        fullName: `${employee.firstName} ${employee.lastName}`,
        email: employee.email,
        phone: employee.phone,
        credentials: employee.sections,
        projectsName: employee.projects,
        roles: employee.roles.map(role => role.name),
      };
    },
  },

  cancelRequestNewEmployees: null,
};
</script>

<style lang="scss">
.employees {
  height: 100%;

  &--archive {
    .text-start {
      color: rgba($--black-color-0, 0.5);
    }
  }

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }
}
</style>
