<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="!isArchive && media.isMobile" :to="employeeRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_employees') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isArchive" @click="unarchiveEmployees">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-up</v-icon>
          {{ $t('button.unarchive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isActive" @click="archiveEmployees">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-down</v-icon>
          {{ $t('button.archive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="media.isMobile" @click="selectAll">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-marked</v-icon>
          {{ $t('button.select_all') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Constants
import * as entityStatuses from '@/constants/entityStatuses';
import { EMPLOYEES_CREATE } from '@/constants/routes';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'EmployeesMenu',

  components: { IconButton },

  inject: ['media'],

  props: {
    status: { type: String, required: true },
    page: { type: Number, default: 1 },
    isProcessing: { type: Boolean, default: false },
  },

  computed: {
    isActive() {
      return this.status === entityStatuses.ACTIVE;
    },

    isArchive() {
      return this.status === entityStatuses.ARCHIVED;
    },

    employeeRoute() {
      return { name: EMPLOYEES_CREATE, query: { prevPage: this.page } };
    },
  },

  methods: {
    unarchiveEmployees() {
      this.$emit('unarchive-employees');
    },

    archiveEmployees() {
      this.$emit('archive-employees');
    },

    selectAll() {
      this.$emit('select-employees');
    },
  },
};
</script>
